/**
 * Created by karthikeyan.k on 23-11-2018.
 */
import { PageConstant } from '../constant/page.constant';

export class LobbyManifest {
  public static manifestVersion = 1;
  private static loadTimeout = 1000 * 15; // 15 seconds
  private static tSuffix = `?t=${PageConstant.VERSION}`;

  public static fc21SliderDesktopManifest(lang: string = 'en'): any {
    const prefix =
      'assets/images/lobby_sprite_images/allgames/lobby_banner_fc21_desktop';

    const maniFestArr = [
      {
        id: 'fc21banner2',
        src: `${prefix}/${lang}/${lang}_fc21_lobby_slider_2@3x.webp${LobbyManifest.tSuffix}`,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21banner3',
        src: `${prefix}/${lang}/${lang}_fc21_lobby_slider_3@3x.webp${LobbyManifest.tSuffix}`,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21banner4',
        src: `${prefix}/${lang}/${lang}_fc21_lobby_slider_4@3x.webp${LobbyManifest.tSuffix}`,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21banner5',
        src: `${prefix}/${lang}/${lang}_fc21_lobby_slider_5@3x.webp${LobbyManifest.tSuffix}`,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static fcbSliderDesktopManifest(lang: string = 'en'): any {
    const prefix =
      'assets/images/lobby_sprite_images/allgames/lobby_banner_fcb_desktop';
    const loadTimeout = LobbyManifest.loadTimeout;

    const maniFestArr = [
      {
        id: 'fcbbanner2',
        src: `${prefix}/${lang}/${lang}_fcb_lobby_slider_2@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fcbbanner3',
        src: `${prefix}/${lang}/${lang}_fcb_lobby_slider_3@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fcbbanner4',
        src: `${prefix}/${lang}/${lang}_fcb_lobby_slider_4@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fcbbanner5',
        src: `${prefix}/${lang}/${lang}_fcb_lobby_slider_5@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static fc21SliderMobileManifest(lang: string = 'en'): any {
    const prefix =
      'assets/images/lobby_sprite_images/allgames/lobby_banner_fc21_mobile';
    const loadTimeout = LobbyManifest.loadTimeout;

    const maniFestArr = [
      {
        id: 'fc21mobilebanner2',
        src: `${prefix}/${lang}/${lang}_fc21_lobby_slider_2_mobile@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fc21mobilebanner3',
        src: `${prefix}/${lang}/${lang}_fc21_lobby_slider_3_mobile@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fc21mobilebanner4',
        src: `${prefix}/${lang}/${lang}_fc21_lobby_slider_4_mobile@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fc21mobilebanner5',
        src: `${prefix}/${lang}/${lang}_fc21_lobby_slider_5_mobile@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static fcbSliderMobileManifest(lang: string = 'en'): any {
    const prefix =
      'assets/images/lobby_sprite_images/allgames/lobby_banner_fcb_mobile';
    const loadTimeout = LobbyManifest.loadTimeout;

    const maniFestArr = [
      {
        id: 'fcbmobilebanner2',
        src: `${prefix}/${lang}/${lang}_fcb_lobby_slider_2_mobile@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fcbmobilebanner3',
        src: `${prefix}/${lang}/${lang}_fcb_lobby_slider_3_mobile@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fcbmobilebanner4',
        src: `${prefix}/${lang}/${lang}_fcb_lobby_slider_4_mobile@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
      {
        id: 'fcbmobilebanner5',
        src: `${prefix}/${lang}/${lang}_fcb_lobby_slider_5_mobile@2x.webp${LobbyManifest.tSuffix}`,
        loadTimeout,
      },
    ];

    return maniFestArr;
  }

  public static menuManifest(): any {
    const maniFestArr = [
      {
        id: 'menu_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/menu_sprite_images/menu_sprite_images_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'menu_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/menu_sprite_images/menu_sprite_images_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static fc21DesktopManifest(lang): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcc_logo',
        src:
          'assets/images/common/logo/fcc_logo_320@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fc21_desktop_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fc21_desktop/bg_fc21_desktop_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fc21_desktop_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fc21_desktop/bg_fc21_desktop_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21_lobby_table@3x',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fc21_desktop/' +
          lang +
          '/' +
          lang +
          '_fc21_lobby_table@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21_lobby_table_limit_sign_text',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fc21_desktop/' +
          lang +
          '/' +
          lang +
          '_fc21_lobby_table_limit_sign_text@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21banner1',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fc21_desktop/' +
          lang +
          '/' +
          lang +
          '_fc21_lobby_slider_1@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fc21_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fc21/tile_bg_fc21_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fc21_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fc21/tile_bg_fc21_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fc21_both_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fc21_both_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static fcbDesktopManifest(lang): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcc_logo',
        src:
          'assets/images/common/logo/fcc_logo_320@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_desktop_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_desktop/bg_fcb_desktop_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_desktop_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_desktop/bg_fcb_desktop_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcb_lobby_table@3x',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fcb_desktop/' +
          lang +
          '/' +
          lang +
          '_fcb_lobby_table@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcb_lobby_table_limit_sign_text',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fcb_desktop/' +
          lang +
          '/' +
          lang +
          '_fcb_lobby_table_limit_sign_text@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcbbanner1',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fcb_desktop/' +
          lang +
          '/' +
          lang +
          '_fcb_lobby_slider_1@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fcb_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fcb/tile_bg_fcb_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fcb_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fcb/tile_bg_fcb_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fcb_both_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fcb_both_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  /*fc21 tab for  mobile screen*/
  public static fc21MobileManifest(lang, isMobile = false): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'mobile_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/mobile/mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'mobile_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/mobile/mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fc21_mobile_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fc21_mobile/bg_fc21_mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fc21_mobile_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fc21_mobile/bg_fc21_mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fc21_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fc21/tile_bg_fc21_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fc21_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fc21/tile_bg_fc21_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fc21_both_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fc21_both_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21mobilebanner1',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fc21_mobile/' +
          lang +
          '/' +
          lang +
          '_fc21_lobby_slider_1_mobile@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21_lobby_table_mobilefor_overlay@3x',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fc21_mobile/' +
          lang +
          '/' +
          lang +
          '_fc21_lobby_table_mobilefor_overlay@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21_lobby_table_limit_sign_text_mobile@3x',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fc21_mobile/' +
          lang +
          '/' +
          lang +
          '_fc21_lobby_table_limit_sign_text_mobile@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },

      {
        id: 'logo_desktop_img',
        src:
          'assets/images/common/logo/fcc_logo_320@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }
  /*fcb tab for  mobile screen*/
  public static fcbMobileManifest(lang, isMobile = false): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'mobile_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/mobile/mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'mobile_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/mobile/mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_mobile_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_mobile/bg_fcb_mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_mobile_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_mobile/bg_fcb_mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fcb_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fcb/tile_bg_fcb_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fcb_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fcb/tile_bg_fcb_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fcb_both_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fcb_both_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcbmobilebanner1',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fcb_mobile/' +
          lang +
          '/' +
          lang +
          '_fcb_lobby_slider_1_mobile@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcb_lobby_table_mobilefor_overlay@3x',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fcb_mobile/' +
          lang +
          '/' +
          lang +
          '_fcb_lobby_table_mobilefor_overlay@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcb_lobby_table_limit_sign_text_mobile@3x',
        src:
          'assets/images/lobby_sprite_images/allgames/lobby_banner_fcb_mobile/' +
          lang +
          '/' +
          lang +
          '_fcb_lobby_table_limit_sign_text_mobile@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'logo_desktop_img',
        src:
          'assets/images/common/logo/fcc_logo_320@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },

      {
        id: 'bg_fcb_mobile_img' /*lobby_bg_mobile ==> SpriteCacheService*/,
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_mobile/bg_fcb_mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_mobile_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_mobile/bg_fcb_mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  /*selet table screen for fc21 desktop*/
  public static selectGamesFC21DesktopManifest(lang, isMobile = false): any {
    const maniFestArr = [
      {
        id: 'cards_sprite_img',
        src:
          'assets/images/game_spritesheets/common/cardsprite_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'cards_sprite_json',
        src:
          'assets/images/game_spritesheets/common/cardsprite_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'chips_sprite_img',
        src:
          'assets/images/game_spritesheets/common/chips.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'chips_sprite_json',
        src:
          'assets/images/game_spritesheets/common/chips.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'button_sprite_img',
        src:
          'assets/images/game_spritesheets/common/buttonblank.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'button_sprite_json',
        src:
          'assets/images/game_spritesheets/common/buttonblank.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_button_sprite_img',
        src:
          'assets/images/game_spritesheets/' +
          lang +
          '/' +
          lang +
          '_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_button_sprite_json',
        src:
          'assets/images/game_spritesheets/' +
          lang +
          '/' +
          lang +
          '_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fc21_desktop_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fc21_desktop/bg_fc21_desktop_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fc21_desktop_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fc21_desktop/bg_fc21_desktop_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fc21_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fc21/tile_bg_fc21_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fc21_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fc21/tile_bg_fc21_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fc21_both_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fc21_both_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21_icon_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/fc21/fc21_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21_icon_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/fc21/fc21_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'logo_desktop_img',
        src:
          'assets/images/common/logo/fcc_logo_320@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'select_table_tile_fc21_sprite_img' /*lobby_selecttable_bg_both ==> SpriteCacheService*/,
        src:
          'assets/images/lobby_sprite_images/selecttable/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'select_table_tile_fc21_sprite_json',
        src:
          'assets/images/lobby_sprite_images/selecttable/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }
  /*selet table screen for fc21 mobile*/
  public static selectGamesFC21MobileManifest(lang, isMobile?): any {
    const maniFestArr = [
      {
        id: 'cards_sprite_img',
        src:
          'assets/images/game_spritesheets/common/cardsprite_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'cards_sprite_json',
        src:
          'assets/images/game_spritesheets/common/cardsprite_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'chips_sprite_img',
        src:
          'assets/images/game_spritesheets/common/chips.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'chips_sprite_json',
        src:
          'assets/images/game_spritesheets/common/chips.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'button_sprite_img',
        src:
          'assets/images/game_spritesheets/common/buttonblank.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'button_sprite_json',
        src:
          'assets/images/game_spritesheets/common/buttonblank.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_button_sprite_img',
        src:
          'assets/images/game_spritesheets/' +
          lang +
          '/' +
          lang +
          '_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_button_sprite_json',
        src:
          'assets/images/game_spritesheets/' +
          lang +
          '/' +
          lang +
          '_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'mobile_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/mobile/mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'mobile_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/mobile/mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fc21_mobile_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fc21_mobile/bg_fc21_mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fc21_mobile_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fc21_mobile/bg_fc21_mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fc21_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fc21/tile_bg_fc21_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fc21_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fc21/tile_bg_fc21_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fc21_both_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fc21_both_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21_icon_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/fc21/fc21_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fc21_icon_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/fc21/fc21_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'logo_desktop_img',
        src:
          'assets/images/common/logo/fcc_logo_320@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'select_table_tile_fc21_sprite_img' /*lobby_selecttable_bg_both ==> SpriteCacheService*/,
        src:
          'assets/images/lobby_sprite_images/selecttable/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'select_table_tile_fc21_sprite_json',
        src:
          'assets/images/lobby_sprite_images/selecttable/tile_fc21_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }
  /*selet table screen for fcb mobile*/
  public static selectGamesFCBMobileManifest(lang, isMobile?): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'mobile_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/mobile/mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'mobile_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/mobile/mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_mobile_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_mobile/bg_fcb_mobile_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_mobile_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_mobile/bg_fcb_mobile_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fcb_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fcb/tile_bg_fcb_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fcb_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fcb/tile_bg_fcb_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fcb_both_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fcb_both_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcb_icon_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/fcb/fcb_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcb_icon_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/fcb/fcb_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'logo_desktop_img',
        src:
          'assets/images/common/logo/fcc_logo_320@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'select_table_tile_fcb_sprite_img' /*lobby_selecttable_bg_both ==> SpriteCacheService*/,
        src:
          'assets/images/lobby_sprite_images/selecttable/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'select_table_tile_fcb_sprite_json',
        src:
          'assets/images/lobby_sprite_images/selecttable/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }
  /*selet table screen for fcb desktop*/
  public static selectGamesFCBDesktopManifest(lang, isMobile?): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_desktop_sprite_img' /*lobby_bg_desktop ==> SpriteCacheService*/,
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_desktop/bg_fcb_desktop_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'bg_fcb_desktop_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/bg_fcb_desktop/bg_fcb_desktop_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fcb_sprite_img' /*tile_underlay_desktop ==> SpriteCacheService*/,
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fcb/tile_bg_fcb_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_bg_icon_fcb_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/tile_bg/tile_bg_fcb/tile_bg_fcb_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'logo_desktop_img',
        src:
          'assets/images/common/logo/fcc_logo_320@3x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fcb_both_sprite_img',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tile_fcb_both_sprite_json',
        src:
          'assets/images/lobby_sprite_images/allgames/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcb_icon_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/fcb/fcb_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'fcb_icon_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/fcb/fcb_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },

      {
        id: 'select_table_tile_fcb_sprite_img' /*lobby_selecttable_bg_both ==> SpriteCacheService*/,
        src:
          'assets/images/lobby_sprite_images/selecttable/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'select_table_tile_fcb_sprite_json',
        src:
          'assets/images/lobby_sprite_images/selecttable/tile_fcb_both/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static historyMobileManifest(lang): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lobby_icons_sprite_img' /*lobby_icon ==> SpriteCacheService*/,
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/lobby_icons_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lobby_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/lobby_icons_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/menu_sprite_images/menu_sprite_images_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/menu_sprite_images/menu_sprite_images_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static historyDesktopManifest(lang): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static historyManifest(isMobile = false, lang = ''): any {
    const maniFestArr = [
      {
        id: 'languagechange_scss',
        src: 'styles/i18n/' + lang + '.css',
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_img',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'common_icons_sprite_json',
        src:
          'assets/images/lobby_sprite_images/common/lobby_icons/common/common_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_img',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'lang_sprite_json',
        src:
          'assets/images/lobby_sprite_images/lang_sprite_images/' +
          lang +
          '_fcg/' +
          lang +
          '_fcg_spriteicon.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'history_cards_sprite_img',
        src:
          'assets/images/game_spritesheets/common/history_cards.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'history_cards_sprite_json',
        src:
          'assets/images/game_spritesheets/common/history_cards.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'history_icons_sprite_img',
        src:
          'assets/images/game_spritesheets/common/history_icons.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'history_icons_sprite_json',
        src:
          'assets/images/game_spritesheets/common/history_icons.json?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static miscManifest(language = 'en'): any {
    const maniFestArr = [
      {
        id: 'favorites_bg',
        src: 'assets/images/common/favorites_bg.webp?t=' + PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'alert_box_gold',
        src:
          'assets/images/common/fc21_alert_box_gold.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'popup_bg_medium',
        src:
          'assets/images/common/popup_bg_medium.webp?t=' + PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'popup_bg_small',
        src:
          'assets/images/common/popup_bg_small.webp?t=' + PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'popup-bg',
        src: 'assets/images/common/popup-bg.webp?t=' + PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'symbol_bg',
        src: 'assets/images/common/symbol_bg.webp?t=' + PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'alert_box_icon',
        src:
          'assets/images/lobby_sprite_images/common/menu_sprite_images/fc21_alert_box_icon.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'close',
        src:
          'assets/images/lobby_sprite_images/common/menu_sprite_images/close.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC21BG',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_Background@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2100',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_00_Index@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2101',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_01_Introduction@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      // {
      //   id: 'tutorialFCBBG',
      //   src:
      //     'assets/images/help/fcb/common/bg_fcb_tutorials_00_Gold_Underlay@2x.webp?t=' +
      //     PageConstant.VERSION,
      //   loadTimeout: LobbyManifest.loadTimeout,
      // },
      // {
      //   id: 'tutorialFCB01',
      //   src:
      //     'assets/images/help/fcb/' +
      //     language +
      //     '/' +
      //     language +
      //     '_fcb_tutorials_01_Index@2x.webp?t=' +
      //     PageConstant.VERSION,
      //   loadTimeout: LobbyManifest.loadTimeout,
      // },
      // {
      //   id: 'tutorialFCB02',
      //   src:
      //     'assets/images/help/fcb/' +
      //     language +
      //     '/' +
      //     language +
      //     '_fcb_tutorials_02_Full_Color_Cards@2x.webp?t=' +
      //     PageConstant.VERSION,
      //   loadTimeout: LobbyManifest.loadTimeout,
      // },
    ];
    return maniFestArr;
  }

  public static fc21TutorialManifest(language = 'en'): any {
    const maniFestArr = [
      {
        id: 'tutorialFC2102',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_02_Scores@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2103',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_03_GameAction@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2104',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_04_GameRules@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2105',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_05_Soft17@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2106',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_06_StartAGame@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2107',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_07_NaturalHands@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2108',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_08_SNWins@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2109',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_09_N21Wins@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2110',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_10_DNWins@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2111',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_11_NZWins@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2112',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_12_BustOver22@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2113',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_13_Splits_5-9@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2114',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_14_ImpossibleSplits@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2115',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_15_DoubleDown@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2116',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_16_ColorInsurance@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2117',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_17_WhiteInsurance@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2118',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_18_Russian_Roulette@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2119',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_19_PremiumPayouts@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2120',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_AboutUs@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2121',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_QuickKeys@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2122',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_General@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFC2123',
        src:
          'assets/images/help/fc21/' +
          language +
          '/' +
          language +
          '_Tutorial_General_No_RTP@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }

  public static fcbTutorialManifest(language = 'en'): any {
    const maniFestArr = [
      {
        id: 'tutorialFCB03',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_03_Scores@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB04',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_04_Ways_to_Win@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB05',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_05_Game_Rules@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB06',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_06_Hand_Scoring@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB07',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_07_Deal_Scoring@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB08',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_08_Draw_Scoring@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB09',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_09_Spread_Banker_Chases_Player@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB10',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_10_Spread_Player_Chases_Banker@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB11',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_11_Spread_Dual_Chase@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB12',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_12_Spread_Game_Over@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB13',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_13_Tie_Game_Rules@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB14',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_14_Commission_Rules@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB15',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_15_Side_Bets@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB16',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_16_Session_Bets@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB17',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_17_Live_Side_Bets@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB18',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_18_Raw_Scores_and_Hand_Ranks@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB19',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_19_Road_Maps@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      {
        id: 'tutorialFCB20',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_20_Quick_Keys@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
      ,
      {
        id: 'tutorialFCB21',
        src:
          'assets/images/help/fcb/' +
          language +
          '/' +
          language +
          '_fcb_tutorials_21_Game_Summary@2x.webp?t=' +
          PageConstant.VERSION,
        loadTimeout: LobbyManifest.loadTimeout,
      },
    ];
    return maniFestArr;
  }
}
