<div class="pre-loader-bg translate-top-most"></div>
<app-ellipsis-loader *ngIf="!currentLanguage"></app-ellipsis-loader>
<ng-container *ngIf="currentLanguage">
  <div class="translate-top-most background-black" [resizeDom]="layoutdata">
    <img
      class="gameImage21ornothing"
      [src]="
        './assets/images/logo/' +
        currentLanguage +
        '/' +
        currentLanguage +
        '_loading_screen_' +
        moduleName +
        '_logo@2x.webp'
      "
      (load)="onGameImageload()"
    />

    <img
      class="fullcolorcardscrownImage"
      [src]="'assets/images/common/full_color_cards_crown_interrstitial.webp'"
    />
    <img
      class="fullcolorfcclogoImage"
      [src]="
        './assets/images/logo/' +
        currentLanguage +
        '/' +
        currentLanguage +
        '_loading_screen_fcc_logo@2x.webp'
      "
    />
  </div>

  <div
    [ngStyle]="{
      'font-size': loadingNameFontSize * scaleR.scaleX + 'px'
    }"
    [ngClass]="currentLanguage + '-lang-specific-font-name'"
    class="disp-text-resize lang-specific-font-name"
    [resizeDom]="loadingTextLayout"
  >
    {{ loadingText }}
  </div>
</ng-container>
