import { OperatingSystemType, DeviceName } from './../constant/device.constant';
import { LobbyManifest } from './../manifest/lobby.manifest';
import { LoaderService } from 'src/app/services/loader.service';
/**
 * Created by karthik.ar on 3/28/2017.
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageConstant } from '../constant/page.constant';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LanguageConstant } from '../constant/language.constant';
import { Observable, Subject } from 'rxjs';
import Decimal from 'decimal.js/decimal.js';

@Injectable()
export class UtilService {
  currency = '';
  currentLanguage;
  isUpdateLanguage = false;
  showAppPreloader = false;
  rediretURLFromGame = 'fcg';
  isLoadedFromGame = false;
  gameEnvironment = '';
  rngServerURL = '';
  deviceInfo = null;
  isSecretCode = false;
  isOnlyCurrencySymbol;

  public parentHeight = 0;
  private _currentYear;
  private isDesktpView = true;
  private viewMde = 'desktop';
  private isViewChngdByUser = false;
  //private fcgChipVal = [.25, .5, 1, 5, 25, 100, 500, 1000, 5000, 10000, 25000, 50000, 100000, 500000, 1000000, 5000000];
  private fcgChipVal = [
    0.01, 0.05, 0.1, 0.25, 0.5, 1, 2.5, 5, 25, 100, 500, 1000, 5000, 10000,
    25000, 50000, 100000, 500000, 1000000, 5000000,
  ];

  settings: any = {
    language: 'en',
    volume: 1,
    voice_sound: 'on',
    game_sound: 'on',
    felt_color: 'blue',
    deal_speed: 'normalplay',
    deal_option: '3',
    commission: 5,
  };
  public reverseSeatNumber = [0, 7, 6, 5, 4, 3, 2, 1];
  constructor(
    private router: Router,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private loaderService: LoaderService
  ) {
    this._currentYear = new Date().getFullYear();
    // console.log('UTILSERVICE CONSTRUCTOR');
    this.loaderService.loadCompleteChange.subscribe(this.onLoadComplete);
  }

  fontRatioChange: Subject<number> = new Subject<number>();
  private _fontRatio: number = 0;

  get fontRatio() {
    return this._fontRatio;
  }

  set fontRatio(value) {
    this._fontRatio = value;
    this.fontRatioChange.next(value);
  }

  get currentYear() {
    return this._currentYear;
  }

  set currentYear(value) {
    this._currentYear = new Date(value).getFullYear();
  }

  scaleChange: Subject<any> = new Subject<any>();
  private _scale: any = {
    scale: 1,
    scaleX: 1,
    scaleY: 1,
    offsetLeft: 0,
    offsetTop: 0,
    isBlockPortrait: false,
  };
  get scale() {
    return this._scale;
  }

  set scale(value) {
    this._scale = value;
    this.scaleChange.next(value);
  }

  convertToJsonToParam(data: Object) {
    const recursiveDecoded = decodeURIComponent($.param(data));
    return recursiveDecoded;
  }

  convertToSymbol(code) {
    const strSplt = code.split(',');
    let currencySymbol = '';
    for (let i = 0; i < strSplt.length; i++) {
      const trimmedCode = strSplt[i].trim(); // Remove any whitespace
      const isHex = trimmedCode.startsWith('x'); // Check if it's hexadecimal
      const value = isHex
        ? parseInt(trimmedCode.slice(1), 16) // Parse hexadecimal value
        : parseInt(trimmedCode, 10); // Parse decimal value
      currencySymbol += String.fromCodePoint(value); // Use fromCodePoint
    }
    return currencySymbol;
  }
  getCurrencySymbolFront(val, currncyCode) {
    if (typeof currncyCode === 'string' && currncyCode.length > 0) {
      return this.setCurrencySymbol(currncyCode) + '' + val;
    } else {
      return this.currency + '' + val;
    }
  }

  getCurrencySymbolBack(val, currncyCode) {
    if (typeof currncyCode === 'string' && currncyCode.length > 0) {
      return val + '' + this.setCurrencySymbol(currncyCode);
    } else {
      return val + '' + this.currency;
    }
  }

  redirectToURL() {
    window.close();
  }

  logout() {}

  setReDirectURL(pageName, tabletype, seat) {
    let currentPageName = this.isDesktopView ? pageName : pageName + 'mobile';
    let queryString =
      pageName === 'fc21tables' || pageName === 'fcbtables'
        ? '_' + tabletype + '_' + seat
        : '';
    this.rediretURLFromGame = '!' + currentPageName + queryString;
  }

  gotoError() {
    this.router.navigate(['/error']);
  }

  setCurrencySymbol(currncyCode, onlySwitch = false) {
    var spaceString = '';
    var currTypeSym;
    var onlyCurrencySymbol;
    currncyCode = currncyCode.toUpperCase();
    if (onlySwitch) {
      this.currency = currncyCode;
    }
    switch (currncyCode) {
      case 'KRW':
        /*
        for finding that code symbol can use this console.log in chrome console(will get expected output )
        example:- console.log(String.fromCharCode(1083, 1074));*/

        currTypeSym = this.convertToSymbol('8361'); /*"₩"*/
        onlyCurrencySymbol = this.convertToSymbol('8361'); /*"₩"*/
        break;
      case 'JP1':
        currTypeSym = this.convertToSymbol('112').toUpperCase();
        onlyCurrencySymbol = this.convertToSymbol('112').toUpperCase();
        break;
      case 'JP2':
        currTypeSym = this.convertToSymbol('112').toUpperCase();
        onlyCurrencySymbol = this.convertToSymbol('112').toUpperCase();
        break;
      case 'MP1':
        currTypeSym = ' ';
        onlyCurrencySymbol = ' ';
        break;
      case 'USD':
        currTypeSym = this.convertToSymbol('36');
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'CAD':
        currTypeSym = this.convertToSymbol('67, 36');
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'AUD':
        currTypeSym = this.convertToSymbol('65, 36');
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'ARS':
        currTypeSym = this.convertToSymbol('36');
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'GBP':
        currTypeSym = this.convertToSymbol('163');
        onlyCurrencySymbol = this.convertToSymbol('163');
        break;
      case 'EUR':
        currTypeSym = this.convertToSymbol('8364');
        onlyCurrencySymbol = this.convertToSymbol('8364');
        break;
      case 'CNY':
        currTypeSym = this.convertToSymbol('165');
        onlyCurrencySymbol = this.convertToSymbol('165');
        break;
      case 'JPY':
        currTypeSym = this.convertToSymbol('165');
        onlyCurrencySymbol = this.convertToSymbol('165');
        break;
      case 'SGD':
        currTypeSym = this.convertToSymbol('83, 36');
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'IDR':
        currTypeSym = this.convertToSymbol('82,112'); /*Rp*/
        onlyCurrencySymbol = this.convertToSymbol('82,112'); /*Rp*/
        break;
      case 'HKD':
        currTypeSym = this.convertToSymbol('72, 75, 36');
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'MYR':
        currTypeSym = this.convertToSymbol('82,77'); /*RM*/
        onlyCurrencySymbol = this.convertToSymbol('82,77'); /*RM*/
        break;
      case 'RMB':
        currTypeSym = this.convertToSymbol('165');
        onlyCurrencySymbol = this.convertToSymbol('165');
        break;
      case 'TWD':
        currTypeSym = this.convertToSymbol('78,84,36'); // 20803 - NT$
        onlyCurrencySymbol = this.convertToSymbol('78,84,36'); // 20803 - NT$
        break;
      case 'PHP':
        currTypeSym = this.convertToSymbol('8369');
        onlyCurrencySymbol = this.convertToSymbol('8369');
        break;
      case 'THB':
        currTypeSym = this.convertToSymbol('3647');
        onlyCurrencySymbol = this.convertToSymbol('3647');
        break;
      case 'INR':
        currTypeSym = this.convertToSymbol('8377');
        onlyCurrencySymbol = this.convertToSymbol('8377');
        break;
      case 'PYG':
        currTypeSym = this.convertToSymbol('103,115'); /*gs*/
        onlyCurrencySymbol = this.convertToSymbol('103,115'); /*gs*/
        break;
      case 'DOP':
        currTypeSym = this.convertToSymbol('82,68,36'); /*RD$*/
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'DKK':
        currTypeSym = this.convertToSymbol('107, 114, 46');
        onlyCurrencySymbol = this.convertToSymbol('107, 114, 46');
        break;
      case 'NZD':
        currTypeSym = this.convertToSymbol('36');
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'NOK':
        currTypeSym = this.convertToSymbol('107, 114'); /*kr*/
        onlyCurrencySymbol = this.convertToSymbol('107, 114');
        break;
      case 'CHF':
        currTypeSym = this.convertToSymbol('70, 114'); /*Fr*/
        onlyCurrencySymbol = this.convertToSymbol('70, 114');
        break;
      case 'ZAR':
        currTypeSym = this.convertToSymbol('82');
        onlyCurrencySymbol = this.convertToSymbol('82');
        break;
      case 'SEK':
        currTypeSym = this.convertToSymbol('107, 114'); /*Fr*/
        onlyCurrencySymbol = this.convertToSymbol('107, 114');
        break;
      case 'RON':
        currTypeSym = this.convertToSymbol('76');
        onlyCurrencySymbol = this.convertToSymbol('76');
        break;
      case 'CZK':
        currTypeSym = this.convertToSymbol('75, 269'); /*Kč*/
        onlyCurrencySymbol = this.convertToSymbol('75, 269');
        break;
      case 'RUB':
        currTypeSym = this.convertToSymbol('8381');
        onlyCurrencySymbol = this.convertToSymbol('8381');
        break;
      case 'VEF':
        currTypeSym = this.convertToSymbol('66, 115'); /*Bs*/
        onlyCurrencySymbol = this.convertToSymbol('66, 115');
        break;
      case 'ILS':
        currTypeSym = this.convertToSymbol('8362');
        onlyCurrencySymbol = this.convertToSymbol('8362');
        break;
      case 'BDT':
        currTypeSym = this.convertToSymbol('66,68,84'); /*BDT*/
        onlyCurrencySymbol = this.convertToSymbol('66,68,84');
        break;
      case 'AMD':
        currTypeSym = this.convertToSymbol('1423');
        onlyCurrencySymbol = this.convertToSymbol('1423');
        break;
      case 'FTN':
        currTypeSym = this.convertToSymbol('9889, xFE0E');
        onlyCurrencySymbol = this.convertToSymbol('9889, xFE0E');
        break;
      case 'VND':
        currTypeSym = this.convertToSymbol('8363');
        onlyCurrencySymbol = this.convertToSymbol('8363');
        break;
      case 'SSP':
        currTypeSym = this.convertToSymbol('8361');
        onlyCurrencySymbol = this.convertToSymbol('8361');
        break;
      case 'PLN':
        currTypeSym = this.convertToSymbol('122, 322'); /*zł*/
        onlyCurrencySymbol = this.convertToSymbol('122, 322');
        break;
      case 'BRL':
        currTypeSym = this.convertToSymbol('82, 36'); /*R$*/
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'MXN':
        currTypeSym = this.convertToSymbol('36');
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'HUF':
        currTypeSym = this.convertToSymbol('70, 116'); /*Ft*/
        onlyCurrencySymbol = this.convertToSymbol('70, 116'); /*Ft*/
        break;
      case 'MOP':
        currTypeSym = this.convertToSymbol('77, 79, 80, 36'); /*MOP$*/
        onlyCurrencySymbol = this.convertToSymbol('36');
        break;
      case 'UAH':
        currTypeSym = this.convertToSymbol('8372');
        onlyCurrencySymbol = this.convertToSymbol('8372');
        break;
      case 'KHR':
        currTypeSym = this.convertToSymbol('6107');
        onlyCurrencySymbol = this.convertToSymbol('6107');
        break;
      case 'TRY':
        currTypeSym = this.convertToSymbol('8378');
        onlyCurrencySymbol = this.convertToSymbol('8378');
        break;
      case 'RSD':
        currTypeSym = this.convertToSymbol('1044, 1080, 1085, 46'); /*Дин*/
        onlyCurrencySymbol = this.convertToSymbol('1044, 1080, 1085, 46');
        break;
      case 'BGN':
        currTypeSym = this.convertToSymbol('1083, 1074'); /*лв*/
        onlyCurrencySymbol = this.convertToSymbol('1083, 1074');
        break;
      case 'GEL':
        currTypeSym = this.convertToSymbol('8382');
        onlyCurrencySymbol = this.convertToSymbol('8382');
        break;
      case 'AED':
        currTypeSym = 'د.إ';
        onlyCurrencySymbol = 'د.إ';
        break;
      case 'SAR':
        currTypeSym = 'ر.س'; // this.convertToSymbol('65020');
        onlyCurrencySymbol = 'ر.س'; // this.convertToSymbol('65020');
        break;
      case 'EGP':
        currTypeSym = this.convertToSymbol('163');
        onlyCurrencySymbol = this.convertToSymbol('163');
        break;
      case 'BTC':
        currTypeSym = ' mBTC';
        onlyCurrencySymbol = ' mBTC';
        if (onlySwitch) {
          this.fcgChipVal = [];
          this.fcgChipVal = [
            0.01, 0.05, 0.1, 0.25, 0.5, 1, 2.5, 5, 25, 100, 500, 1000, 5000,
            10000, 25000, 50000, 100000, 500000, 1000000, 5000000,
          ];
        }
        break;
      case 'MBTC':
        currTypeSym = ' mBTC';
        onlyCurrencySymbol = ' mBTC';
        if (onlySwitch) {
          this.fcgChipVal = [];
          this.fcgChipVal = [
            0.01, 0.05, 0.1, 0.25, 0.5, 1, 2.5, 5, 25, 100, 500, 1000, 5000,
            10000, 25000, 50000, 100000, 500000, 1000000, 5000000,
          ];
        }
        break;
      default:
        currTypeSym = '';
        onlyCurrencySymbol = '';
        break;
    }
    if (onlySwitch) {
      this.currency = currTypeSym + spaceString;
      this.isOnlyCurrencySymbol = onlyCurrencySymbol + spaceString;
      //console.log(this.currency);
      return this.currency;
    } else {
      return currTypeSym + spaceString;
    }
  }

  getBetChipOrder(mValue: number = 0): Array<string> {
    const mChipOrder = [];
    let count = 0;
    for (let i: number = this.fcgChipVal.length - 1; i >= 0; i--) {
      const mChipValue = Math.floor(mValue / this.fcgChipVal[i]);
      if (mChipValue >= 1) {
        /*  mValue %= this.fcgChipVal[i]; */
        mValue = +new Decimal(mValue).modulo(this.fcgChipVal[i]).toFixed();
        for (let j = 0; j < mChipValue; j++) {
          mChipOrder[count++] = this.numberToLatinForChip([
            this.fcgChipVal[i],
          ]).toLowerCase();
        }
      }
    }
    return mChipOrder;
  }

  numberToLatinForChip(number) {
    if (number === undefined) {
      return;
    }
    let tempNum = number / 1000000000;
    if (tempNum >= 1) {
      return tempNum + 'B';
    }
    tempNum = number / 1000000;
    if (tempNum >= 1) {
      return tempNum + 'MM';
    }
    tempNum = number / 1000;
    if (tempNum >= 1) {
      return tempNum + 'K';
    }
    return number.toString();
  }

  numberToLatin(number) {
    if (number === undefined) {
      return;
    }
    let tempNum = number / 1000000000;
    if (tempNum >= 1) {
      return tempNum + 'B';
    }
    tempNum = number / 1000000;
    if (tempNum >= 1) {
      return tempNum + 'M';
    }
    tempNum = number / 1000;
    if (tempNum >= 1) {
      if (tempNum % 1 !== 0) {
        const tempStr = tempNum.toFixed(2).toString().split('.');
        if (tempStr[1][1] === '0') {
          return tempNum.toFixed(1) + 'K';
        } else {
          return tempNum.toFixed(2) + 'K';
        }
      } else {
        return tempNum + 'K';
      }
    }
    // //console.log('LATIN:::: '+number.toString());
    return number.toString();
  }

  zeroFormatter(param) {
    let _sAmt = param.toString();
    const _nAmt = +param;
    if (_sAmt.indexOf('.') > 0) {
      const _val1 = +_sAmt.split('.')[0];
      const _val2 = +_sAmt.split('.')[1];
      _sAmt = _val2 > 0 ? _nAmt.toFixed(2).toString() : _val1.toString();
    }
    return _sAmt;
  }

  convertStringtoBoolean(sVal) {
    return sVal === 'true' || sVal === '1';
  }

  convertXMLStringToJSON(sVal) {
    // let converted=new parse(sVal);
    // return converted;
  }

  getTimer() {
    return new Date().getTime();
  }

  querystringToJSON(data) {
    data = data.replace(new RegExp('=&#', 'g'), '=|||||'); // Added for FirstName issue in gameInfo
    const reg = new RegExp(/([A-Za-z0-9_]+)=([^&]+)/g);
    const dataObj = {};
    let result;
    while ((result = reg.exec(data))) {
      // perform type conversion
      result[2] = result[2].trim();
      result[2] = result[2].replace('|||||', '&#');
      if (result[2] === 'null') {
        result[2] = null;
      }
      if (result[2] === 'true') {
        result[2] = true;
      }
      if (result[2] === 'false') {
        result[2] = false;
      }

      dataObj[result[1]] = result[2];
    }
    return dataObj;
  }

  loadCSS(url) {
    // Create link
    const link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    link.type = 'text/css';

    const head = document.getElementsByTagName('head')[0];
    const links = head.getElementsByTagName('link');
    const style = head.getElementsByTagName('style')[0];

    // Check if the same style sheet has been loaded already.
    let isLoaded = false;
    for (let i = 0; i < links.length; i++) {
      const node = links[i];
      if (node.href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) {
      return;
    }
    head.insertBefore(link, style);
  }

  /**
   * Method add the favicon
   * @method changeFavIcon
   * @params url
   */
  public changeFavIcon = (url: string): void => {
    let favicon = document.querySelector('link[rel="shortcut icon"]');
    if (!favicon) {
      favicon = document.createElement('link');
      favicon.setAttribute('rel', 'shortcut icon');
      const head = document.querySelector('head');
      head.appendChild(favicon);
    }
    favicon.setAttribute('type', 'image/webp');
    // //console.log(' changeFavIcon : '+url);
    favicon.setAttribute('href', url);
  };

  public removeWhiteSpace_ApplyUnderScore(content) {
    const result = content.replace(/ +/g, '_').toUpperCase();
    return result;
  }

  public formatQueryString(data) {
    if (data == null || data === undefined) {
      return '';
    }
    const params = [];
    for (const n in data) {
      if (data.hasOwnProperty(n)) {
        params.push(n + '=' + data[n]);
      }
    }
    return params.join('&');
  }

  getCardValue(cardNumber: number): string {
    // //console.log('GetCardValue: ' + cardNumber);
    let cardColor = '';
    let cardColorCode = 0;
    let cardValue = 0;
    let returnString = '';
    if (cardNumber >= 0) {
      cardColorCode = Math.floor(cardNumber / 11);
      cardValue = cardNumber % 11 === 0 ? 1 : (cardNumber % 11) + 1;
      // //console.log(cardColorCode);
      // //console.log(cardValue);
      switch (cardColorCode) {
        case 0:
          cardColor = 'green';
          break;
        case 1:
          cardColor = 'orange';
          break;
        case 2:
          cardColor = 'purple';
          break;
        case 3:
          cardColor = 'blue';
          break;
        case 4:
          cardColor = 'white';
          break;
      }
      returnString = cardColor + '_' + cardValue;
    } else {
      returnString = 'back';
    }
    return returnString;
  }

  getGameSize() {
    const windowWidth: number = window.innerWidth;
    const windowHeight: number =
      this.parentHeight != 0 ? this.parentHeight : window.innerHeight;
    let GAME_WIDTH, GAME_HEIGHT;
    if (windowWidth > windowHeight) {
      GAME_WIDTH = 1280;
      GAME_HEIGHT = 720;
    } else {
      GAME_WIDTH = 720;
      GAME_HEIGHT = 1280;
    }
    // GAME_WIDTH = (window.innerWidth > window.innerHeight)?1280:720;
    // GAME_HEIGHT = (window.innerWidth > window.innerHeight)?720:1280;
    return { width: GAME_WIDTH, height: GAME_HEIGHT };
  }

  getOSType() {
    const osList = this.deviceService.os.toLowerCase();
    if (osList.indexOf('windows') !== -1) {
      return OperatingSystemType.WINDOWS;
    } else if (osList.indexOf('mac') !== -1) {
      return OperatingSystemType.MAC;
    } else if (osList.indexOf('ios') !== -1) {
      return OperatingSystemType.IOS;
    } else if (osList.indexOf('android') !== -1) {
      return OperatingSystemType.ANDROID;
    } else if (osList.indexOf('linux') !== -1) {
      return OperatingSystemType.LINUX;
    } else {
      return OperatingSystemType.OTHERS;
    }
  }

  getDeviceName(): any {
    const deviceTypeValue = this.deviceService.getDeviceInfo();
    let deviceName = deviceTypeValue.device.toLowerCase();
    const isTablet = this.deviceService.isTablet();
    const isDesktop = this.deviceService.isDesktop();
    if (deviceName.indexOf('unknown') !== -1 && isDesktop) {
      return DeviceName.WINDOWS;
    } else if (deviceName.indexOf('android') !== -1) {
      return DeviceName.ANDROID;
    } else if (deviceName.indexOf('firefox-os') !== -1) {
      return DeviceName.FIREFOX;
    } else if (deviceName.indexOf('ipad') !== -1 || isTablet) {
      return DeviceName.IPAD; // Full screen not supported
    } else if (deviceName.indexOf('mac') !== -1 && isDesktop) {
      return DeviceName.MACINOTOSH;
    } else if (
      deviceName.indexOf('iphone') !== -1 ||
      deviceName.indexOf('mac') !== -1
    ) {
      return DeviceName.IPHONE; // Full screen not supported
    } else {
    }
    return deviceName;
  }

  set isViewChangedByUser(isViewChanged) {
    this.isViewChngdByUser = isViewChanged;
  }

  get isViewChangedByUser(): boolean {
    return this.isViewChngdByUser;
  }

  get isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  set viewMode(mode) {
    this.viewMde = mode;
    switch (mode) {
      case PageConstant.DESKTOP:
        this.isDesktpView = true;
        break;
      case PageConstant.MOBILE:
        this.isDesktpView = false;
        break;
    }
  }

  get viewMode(): string {
    return this.viewMde;
  }

  get isDesktopView(): boolean {
    return this.isDesktpView;
  }

  loadMenu: Subject<any> = new Subject<any>();
  loadBaccarat: Subject<any> = new Subject<any>();
  loadMisc: Subject<any> = new Subject<any>();

  onLoadComplete = (event) => {
    // console.log('UTILSERVICE ON LOAD COMPLETE' + event);
    let height = document.body.clientHeight;
    window.parent.postMessage(['setHeight', height], '*');
    if (event != undefined && this[event]) {
      this[event].next();
    }
    /* if(event == 'loadMenu') {
      this.loadMenu.next(true);
    }
    if(event == 'loadBaccarat') {
      this.loadBaccarat.next(true);
    }
    if(event == 'loadMisc') {
      this.loadMisc.next(true);
    } */
  };

  menuLoadComplete(): Observable<any> {
    return this.loadMenu.asObservable();
  }

  baccaratLoadComplete(): Observable<any> {
    return this.loadBaccarat.asObservable();
  }

  miscLoadComplete(): Observable<any> {
    return this.loadMisc.asObservable();
  }

  loadMenuManifest() {
    // console.log('Load Menu Manifest');
    this.loaderService.loadImages(LobbyManifest.menuManifest(), 'loadMenu');
  }

  loadFCBManifestForAllGames(isMobile = false) {
    let manifest = isMobile
      ? LobbyManifest.fcbMobileManifest(this.currentLanguage)
      : LobbyManifest.fcbDesktopManifest(this.currentLanguage);
    this.loaderService.loadImages(manifest, 'loadBaccarat');
  }

  loadMiscManifest() {
    // console.log('MISC IMAGES');
    this.loaderService.loadImages(
      LobbyManifest.miscManifest(this.currentLanguage),
      'loadMisc'
    );
  }

  loadfc21TutorialManifest() {
    // console.log('MISC IMAGES');
    this.loaderService.loadImages(
      LobbyManifest.fc21TutorialManifest(this.currentLanguage),
      'loadMisc'
    );
  }

  loadfcbTutorialManifest() {
    // console.log('MISC IMAGES');
    this.loaderService.loadImages(
      LobbyManifest.fcbTutorialManifest(this.currentLanguage),
      'loadMisc'
    );
  }
}
